export default function isScrolling() {
  if (typeof window !== "undefined" && window.document) {
    window.addEventListener("scroll", () => {
      if (window.scrollY <= 0) {
        // back at the top
        window.document.querySelector(".Page").classList.remove("is-scrolling")
        window.document
          .querySelector(".Header")
          .classList.remove("is-scrolling")
      } else {
        // going past the top ie - scrolling down
        window.document.querySelector(".Page").classList.add("is-scrolling")
        window.document.querySelector(".Header").classList.add("is-scrolling")
      }
    })
  }
}
